import { CSSProperties, forwardRef, MouseEventHandler } from 'react';
import classNames from 'classnames';

import Link, { Props as LinkProps } from '@components/atoms/Link';

import classes from './PossibleLink.module.scss';

export type Props = Omit<LinkProps, 'href' | 'onMouseEnter' | 'onClick'> & {
  style?: CSSProperties;
  href?: LinkProps['href'];
  onMouseEnter?: () => void;
  onClick?: (e: Event) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PossibleLink = forwardRef<any, Props>(
  ({ className, style, children, href, onMouseEnter, onClick, ...rest }, ref) =>
    href ? (
      <Link
        className={className}
        style={style}
        ref={ref}
        href={href}
        onMouseEnter={onMouseEnter}
        onClick={onClick as unknown as MouseEventHandler<HTMLAnchorElement>}
        {...rest}
      >
        {children}
      </Link>
    ) : (
      <div
        ref={ref}
        style={style}
        className={classNames(classes.link, className)}
        onMouseEnter={onMouseEnter}
        onClick={onClick as unknown as MouseEventHandler<HTMLDivElement>}
      >
        {children}
      </div>
    )
);

export default PossibleLink;
