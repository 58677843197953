const getKeyValuePair = (key: string, value: string) => `${key}=${encodeURIComponent(value)}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createURLWithQuery = (url: string, data: Record<string, any>): string => {
  const ret: string[] = [];
  let updatedUrl = url;

  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (value !== undefined && value !== '') {
      const matches = updatedUrl.match(`\\[${key}\\]`);
      if (matches) {
        updatedUrl = updatedUrl.replace(`[${key}]`, value);
      } else if (Array.isArray(value)) {
        value.forEach((el1) => {
          ret.push(getKeyValuePair(key, el1));
        });
      } else {
        ret.push(getKeyValuePair(key, value));
      }
    }
  });

  return `${updatedUrl}${ret.length ? '?' : ''}${ret.join('&')}`;
};

export default createURLWithQuery;
