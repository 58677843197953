import { ParsedUrlQueryInput } from 'querystring';
import { UrlObject } from 'url';

import createURLWithQuery from 'utils/createUrlWithQuery';
import getSortedQueryParams from 'utils/getSortedQueryParams';

const convertUrlObjectToString = (url?: UrlObject) =>
  url?.pathname
    ? createURLWithQuery(url.pathname, getSortedQueryParams((url?.query || {}) as ParsedUrlQueryInput))
    : '';

export default convertUrlObjectToString;
