import config from 'config';
import { ArticlePreview as ArticlePreviewType } from 'types/models/Article';
import ArticlePreview from '@components/ArticlePreview';
import Section from '@components/Section';
import Link from '@components/atoms/Link';
import Typography from '@components/atoms/Typography';
import FormattedMessage from '@components/FormattedMessage';
import Pagination from '@components/Pagination';

import messages from './ArticleCategoryPreview.messages';
import classes from './ArticleCategoryPreview.module.scss';

const { routes } = config;

type Props = {
  mainArticle?: ArticlePreviewType;
  articles?: ArticlePreviewType[];
  raw?: boolean;
  withFakeLink?: boolean;
  withSpacing?: boolean;
  slug: string;
} & (
  | {
      name: string;
      total?: never;
      page?: never;
      tag?: never;
    }
  | {
      name?: never;
      total: number;
      page: number;
      tag?: string;
    }
);

const ArticleCategoryPreview = ({
  mainArticle,
  articles = [],
  total = 0,
  slug,
  name,
  page,
  tag,
  raw,
  withFakeLink,
  withSpacing,
}: Props): JSX.Element => (
  <Section
    title={name}
    classes={{ root: !withSpacing ? classes.withoutSpacing : undefined, header: classes.title }}
    actions={
      <Link
        className={classes.link}
        href={{ pathname: routes.articles.category.href, query: { category: slug } }}
        underline="hover"
      >
        <Typography variant="body2" renderAs="div">
          <FormattedMessage {...messages.showAll} />
        </Typography>
      </Link>
    }
  >
    {mainArticle && (
      <div className={classes.mainArticle}>
        <ArticlePreview {...mainArticle} variant="big" raw={raw} withFakeLink={withFakeLink} />
      </div>
    )}
    {!!articles.length && (
      <div className={classes.articlesWrapper} id="articles">
        <div className={classes.articles}>
          {articles.map((article) => (
            <ArticlePreview key={article.id} {...article} variant="small" raw={raw} withFakeLink={withFakeLink} />
          ))}
        </div>
        {total > 1 && !!page && (
          <div className={classes.pagiantion}>
            <Pagination
              scroll={false}
              current={page}
              total={total}
              getPageChangeHref={(currentPage) => ({
                pathname: tag ? routes.articles.category.tag.href : routes.articles.category.href,
                query: { category: slug, tag, page: currentPage },
                hash: 'articles',
              })}
            />
          </div>
        )}
      </div>
    )}
  </Section>
);

export default ArticleCategoryPreview;
