import { useContext } from 'react';
import Head from 'next/head';
import classNames from 'classnames';
import { UrlObject } from 'url';

import Url from 'types/Url';
import ArrowIcon from 'assets/icons/arrow2.svg';
import { ConfigContext } from '@providers/ConfigProvider';
import PossibleLink from '@components/atoms/PossibleLink';
import convertUrlObjectToString from 'utils/convertUrlObjectToString';
import { FormattedMessage } from 'utils/intl';

import messages from './Pagination.messages';
import classes from './Pagination.module.scss';

const getAbsoluteUrl = (appUrl: string, url: UrlObject): string => {
  const stringifiedUrl = convertUrlObjectToString(url);
  return `${appUrl}${stringifiedUrl}`;
};

const getNewPage = (page: number): number | undefined => (page <= 1 ? undefined : page);

type Props = {
  current: number;
  total: number;
  scroll?: boolean;
  withFakeLink?: boolean;
  getPageChangeHref: (page: number | undefined) => Url;
  onClick?: (page: number | undefined) => void;
};

const Pagination = ({
  current,
  total,
  withFakeLink,
  scroll = true,
  getPageChangeHref,
  onClick,
}: Props): JSX.Element => {
  const { appUrl } = useContext(ConfigContext);

  const hasPrev = current > 1;
  const hasNext = current < total;

  const previous = <ArrowIcon className={classNames(classes.prevArrow, { [classes.hasPrev]: hasPrev })} />;
  const next = (
    <>
      <span className={classes.nextText}>
        <FormattedMessage {...messages.nextPage} />
      </span>
      <ArrowIcon className={classNames(classes.nextArrow, { [classes.hasNext]: hasNext })} />
    </>
  );

  const previousUrl = getPageChangeHref(getNewPage(current - 1)) as UrlObject;
  const nextUrl = getPageChangeHref(getNewPage(current + 1)) as UrlObject;

  return (
    <>
      {withFakeLink && (
        <Head>
          {hasPrev && <link rel="prev" href={getAbsoluteUrl(appUrl, previousUrl)} key="prev" />}
          {hasNext && <link rel="next" href={getAbsoluteUrl(appUrl, nextUrl)} key="next" />}
        </Head>
      )}
      <div className={classes.wrapper} data-nosnippet>
        {hasPrev ? (
          <PossibleLink
            href={withFakeLink ? undefined : previousUrl}
            dataEid="pagination-previous-page"
            className={classNames(classes.prevLink, classes.isActive)}
            scroll={scroll}
            onClick={
              onClick
                ? (e) => {
                    e.preventDefault();
                    onClick(getNewPage(current - 1));
                  }
                : undefined
            }
          >
            {previous}
          </PossibleLink>
        ) : (
          <div className={classes.prevLink}>{previous}</div>
        )}
        <div className={classes.indicator} data-eid="pagination-indicator">
          {current}/{total}
        </div>
        {hasNext ? (
          <PossibleLink
            href={withFakeLink ? undefined : nextUrl}
            dataEid="pagination-next-page"
            className={classNames(classes.nextLink, classes.isActive)}
            scroll={scroll}
            onClick={
              onClick
                ? (e) => {
                    e.preventDefault();
                    onClick(getNewPage(current + 1));
                  }
                : undefined
            }
          >
            {next}
          </PossibleLink>
        ) : (
          <div className={classes.nextLink}>{next}</div>
        )}
      </div>
    </>
  );
};

export default Pagination;
