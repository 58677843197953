import ContactConfig from 'types/ContactConfig';

/**
 * **NOTE:** It cannot be component, because next/head needs it to be direct children
 */
export const commonMetatags = (
  contact: ContactConfig,
  appUrl: string,
  pageTitle = '',
  description: string = contact.appName,
  imageUrl = '',
  disableIndex = false,
  withCanonicalUrl = false
): JSX.Element => {
  const title = pageTitle || contact.appName;
  const finalDescription = description || contact.appName;

  return (
    <>
      <title>{title}</title>
      {withCanonicalUrl && <link rel="canonical" href={appUrl} key="canonical" />}
      <meta name="robots" content={disableIndex ? 'noindex, nofollow' : 'index, follow'} key="robots" />
      <meta name="language" content="pl" key="language" />
      <meta name="description" content={finalDescription} key="description" />
      <meta property="og:type" content="website" key="og-type" />
      <meta property="og:url" content={appUrl} key="og-url" />
      <meta property="og:title" content={title} key="og-title" />
      <meta property="og:description" content={finalDescription} key="og-description" />
      <meta property="og:image" content={imageUrl} key="og-image" />
      <meta property="og:image:secure_url" content={imageUrl} key="og-image-secure-url" />
      <meta property="og:image:type" content="image/png" key="og-image-type" />
      <meta property="og:image:alt" content={`${contact.appName} logo`} key="og-image-alt" />
      <meta property="og:image:width" content="400" key="og-image-width" />
      <meta property="og:image:height" content="400" key="og-image-height" />
    </>
  );
};
